table#fourthree th,
table#fourthree td {
    /* border: 1px solid black; */
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

table#fourthree th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    width: 25px;
}

table#fourthreetwo td {
    /* border: 1px solid black; */
    padding: 0px;
    text-align: center;
    vertical-align: middle;
}

table#fourthreetwo th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    width: auto;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

table#fourthreetwo thead tr th p
table#fourthreetwo tbody tr td p {
    margin: 0px;
}



/* src/components/css/attendanceRecord.css */

/* Loan Modal Styles */
.loan-modal {
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px; */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* z-index: 1000; */
}

.loan-modal h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

.loan-modal p {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
}

.loan-modal input[type="number"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.loan-modal label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #555;
}

.loan-modal label input[type="checkbox"] {
    margin-right: 10px;
}

.loan-modal button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.loan-modal button:hover {
    background-color: #45a049;
}






table#foursiz th,
table#foursiz td {
    /* border: 1px solid black; */
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

table#foursiz th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
    width: 25px;
}